var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('section',{staticClass:"h-100 mb-5",attrs:{"id":"contentbox"}},[_c('div',{staticClass:"form-login form-horizontal",attrs:{"id":"register"}},[_vm._m(0),_c('div',{staticClass:"bg-white border-secondary shadow-sm rounded p-3 h-100 mt-2"},[_vm._m(1),_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-12",staticStyle:{"display":"flex","justify-contents":"center"}},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"group__keyboard"},[_c('div',{staticClass:"num-pad box__show-number"},[_c('h4',{staticClass:"text-center"},[_vm._v("ระบุตัวเลข 4 หลัก")]),_c('div',{staticClass:"label-number lists d-flex justify-content-center"},[(_vm.number.split('').length > 0)?_c('div',{attrs:{"id":"bet_num"}},_vm._l((_vm.type),function(item,index){return _c('label',{key:index,staticClass:"number"},[_vm._v(_vm._s(_vm.number.split('')[index] ? _vm.number.split('')[index] : ' '))])}),0):_c('div',{attrs:{"id":"bet_num"}},_vm._l((_vm.type),function(index){return _c('label',{key:index,staticClass:"number"})}),0)]),_c('div',{staticClass:"key-pad box__keyboard"},[_c('div',{staticClass:"row p-2"},_vm._l(([
                          1,
                          2,
                          3,
                          'delete',
                          4,
                          5,
                          6,
                          'clear',
                          7,
                          8,
                          9,
                          undefined,
                          null,
                          0,
                          null,
                          undefined
                        ]),function(number,index){return _c('div',{key:index,staticClass:"col-3"},[(number === 'clear')?_c('button',{staticClass:"btn btn-dark btn-block btn-reset",on:{"click":function($event){return _vm.clear()}}},[_c('span',[_vm._v("ล้าง")]),_c('span',[_vm._v("ข้อมูล")])]):(number === 'delete')?_c('button',{staticClass:"btn btn-danger btn-block",attrs:{"data-id":"delete"},on:{"click":function($event){return _vm.deleteLast()}}},[_c('i',{staticClass:"fas fa-backspace"})]):(number === undefined)?_c('div'):_c('button',{class:number === null ? 'btn btn-blank' : 'btn btn-outline-primary btn-block',attrs:{"disabled":number === null ? true : false,"data-id":number},on:{"click":function($event){return _vm.pushNumber(number)}}},[_vm._v(" "+_vm._s(number)+" ")])])}),0)])])])])]),_vm._m(3),_c('div',{staticClass:"col-12 border-bottom my-3"}),_c('div',{staticClass:"col-7 col-sm-7 col-md-6 text-right px-0"},[_c('small',[_vm._v("ส่งรหัสไปยังเบอร์")]),_c('h6',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-mobile-alt"}),_vm._v(" "+_vm._s(_vm.mobilePhone))])]),_c('div',{staticClass:"col-5 col-sm-5 col-md-6 text-left px-2"},[_c('button',{staticClass:"btn btn-outline-dark btn-sm mt-2",on:{"click":_vm.requestAgain}},[_c('i',{staticClass:"fas fa-sync-alt"}),_vm._v(" ส่งอีกครั้ง ")])])])]),_c('div',{staticClass:"bg-secondary border-dark shasow-sm rounded p-3 h-100 my-2 mb-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('router-link',{staticClass:"btn btn-dark btn-block",attrs:{"to":"/hotgame","tag":"button"}},[_c('i',{staticClass:"fas fa-chevron-left"}),_vm._v(" ย้อนกลับ ")])],1),_c('div',{staticClass:"col"},[_c('button',{staticClass:"btn btn-primary btn-block",on:{"click":_vm.onSubmit}},[_vm._v(" ถัดไป "),_c('i',{staticClass:"fas fa-chevron-right"})])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-dark text-white border border-secondary rounded px-3 py-2 mt-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-6"},[_c('div',{staticClass:"indexlogo"})]),_c('div',{staticClass:"col-12 col-sm-12 col-md-6 text-center text-sm-center text-md-right"},[_c('h3',{staticClass:"mt-1 font-weight-light"},[_c('i',{staticClass:"fas fa-key"}),_vm._v(" ลืมรหัสผ่าน")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"text-danger"},[_c('span',{staticClass:"badge badge-pill badge-danger font-weight-light"},[_vm._v("STEP 2")]),_vm._v(" ระบุรหัสยืนยัน ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-sm-12"},[_c('h6',{staticClass:"text-dark"},[_c('i',{staticClass:"fas fa-sms"}),_vm._v(" โปรดนำเลข ที่ได้รับจาก sms มากรอกในช่องว่าง")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-sm-12 text-center"},[_c('small',{staticClass:"text-secondary",staticStyle:{"display":"inline-block","line-height":"1.2"}},[_vm._v(" กรุณาใส่หมายเลขที่ระบบได้ส่งไปยังเบอร์โทรศัพท์ของท่านภายใน 5 นาที ")])])
}]

export { render, staticRenderFns }